import React, { useEffect, useState } from "react";
import Api from "../api/axios";

const Noticias = (props) => {
  const [data, setImage] = useState();
  //const [loading, setLoading] = useState(false);

  useEffect(() => {
    //setLoading(true);

    //console.log(props.img)
    
    if (props.img) {
    
      Api.thumbnail(process.env.REACT_APP_BASE_URL + props.img.path,
        "thumbnail",
        props.width,
        props.heigth
      ).then((resp) => {
        
        setImage(resp);
        //setLoading(false);
      });
    }
   
  }, [props.img]);


    if (data && props.img) {
        return <img className="img-fluid d-block mx-auto" src={data} alt="" />;
    } else {
        return <div></div>
    }
  
 
};

export default Noticias;
