import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../../api/axios";

const InfoLocal = () => {
  const [data, setData] = useState();

  useEffect(() => {
    Api.collection("informacaolocal").then((resp) => {
      setData(resp.entries);
    });
  }, []);

  return (
    <ul>
      {data &&
        data.map((item, key) => (
          <li key={item._id}>
            <Link to={`/page/informacaolocal${item.slug}`}>{item.titulo}</Link>
          </li>
        ))}
    </ul>
  );
};

export default InfoLocal;
