import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Api from "../api/axios";
import Image from "./Image.js";

const List = (item) => {
  return (
    <div className="row single-post">
      <div className="col-md-2">
        {item.item.image && (
          <Image img={item.item.image} width={100} heigth={100} />
        )}
      </div>
      <div className="col-md-10">
        <Link to={"/noticia" + item.item.slug}>
          <h3 className="mt-20 mb-10">{item.item.titulo}</h3>
        </Link>
        {item.item.sumario && <p>{item.item.sumario.substr(0, 220)}...</p>}
        <p className="excert">
          <small>
            <i className="fa fa-calendar" aria-hidden="true"></i> {item.item.data}
          </small>
        </p>
      </div>
    </div>
  );
};

const Grid = (item) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="single-feature">
        <Link to={"/noticia" + item.item.slug}>
          <span className="lnr">
            {item.item.image && <Image img={item.item.image} />}
          </span>
          <h3 className="mt-20 mb-10">{item.item.titulo}</h3>
        </Link>
        {item.item.sumario && <p>{item.item.sumario.substr(0, 120)}...</p>}
        <p>
          <small>{item.item.data}</small>
        </p>
      </div>
    </div>
  );
};

const getNews = (limit, page) => {
  let Pages = limit * page - limit;

  const options = {
    limit: limit,
    sort: { data: -1 },
    populate: 1,
    skip: Pages,
  };

  return Api.collection("noticias", options).then((resp) => {
    return resp;
    //setLoading(false);
  });
};

const Noticias = (props) => {
  const [data, setData] = useState();
  const limit = props.limit;
  const page = 1;

  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    getNews(limit, selected).then((data) => {
      setData(data);
    });
  };

  useEffect(() => {
    //setLoading(true);
    getNews(limit, page).then((data) => {
      setData(data);
    });
  }, []);

  if (!props.list) {
    return (
      <section className="feature-area section-gap-top section-gap-bottom">
        <div className="container">
          <div className="row">
            {data &&
              data.entries.map((file, key) => (
                <Grid item={file} key={key}></Grid>
              ))}
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="post-content-area section-gap-top section-gap-bottom">
        <div className="container">
          {data && (
            <div className="posts-list">
              {data.entries.map((file, key) => (
                <List item={file} key={key}></List>
              ))}
              <div className="blog-pagination justify-content-center d-flex">
              <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Seguinte"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={Math.ceil(data.total / limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                pageClassName={'page-item'}
                activeClassName={"active"}
                pageLinkClassName={'page-link'}
                previousLinkClassName={'page-link'}
                nextLinkClassName={'page-link'}
              />
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
};

export default Noticias;
