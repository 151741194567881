import React, {Suspense, useEffect, useState } from "react";
import { useLocation , Route } from "react-router-dom";

import Header from './components/header';
import Banner from './components/banner';
import About from './components/About';
import Footer from './components/Footer';
import Loader from './components/Loader';

import './App.css';

const Page = React.lazy(() => import('./components/Page'));
const Noticias = React.lazy(() => import('./components/Noticias'));
const Noticia = React.lazy(() => import('./components/Noticia'));

const App = () => {

  let location = useLocation()

  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
      setCurrentPath(location.pathname);
      window.scrollTo(0, 0)
  },[location])

    return (
      <Suspense fallback={<Loader />}>
      <div className="App">
        <div className="main-wrapper">  
          <Header path={currentPath}></Header>
          <Banner path={currentPath}></Banner>    
          <Route exact path="/" component={About} />   
          <Route path="/page/:collection?/:slug?" component={Page} />   
             
          <Route
            path='/noticias'
            render={(props) => <Noticias {...props} list={true} limit={10} />}
          />
          <Route path="/noticia/:slug?" component={Noticia} />   
          <Footer/>
        </div>
      </div>
      </Suspense>
    );
  
}

export default App;
