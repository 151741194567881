import React from "react";
import Video from "./Video";

const Banner = (props) => {

  return (
    <section className={ (props.path !== '/' ? "home-banner-area-small" : "") + " home-banner-area" } >

       <Video/>

      <div className="container">
        <div className="row fullscreen d-flex align-items-center justify-content-between">
          <div className="home-banner-content col-lg-6 col-md-12">
          
            <h1>Cuide de SI, Cuide de TODOS</h1>
            <h2>Município de Estarreja</h2>
            
          </div>
          <div className="banner-img col-lg-4 col-md-6">

          <div className="download-button">
              <div className="buttons flex-row d-flex">
                <i className="fa fa-phone" aria-hidden="true"></i> 
                <div className="desc">
                <a href="tel:234840600">
                    <p>
                    &nbsp;
                  <br />
                  <span>234 840 600</span>
                  </p>
                  </a>
                </div>
              </div>
              <div className="buttons flex-row d-flex">
              <i className="fa fa-medkit" aria-hidden="true"></i>
                <div className="desc">
                  <a href="tel:910076532">
                    <p>
                    SOS VIVER + 
                      <br />
                      <span>910 076 532</span>
                    </p>
                  </a>
                </div>
              </div>
              <div className="buttons flex-row d-flex">
              <i className="fa fa-user-md" aria-hidden="true"></i>
                <div className="desc">
                  <a href="tel:967129721">
                    <p>
                    Cuidar à Distância 
                      <br />
                      <span>925 651 607</span>
                    </p>
                  </a>
                </div>
              </div>
            </div>


          </div>
          
        </div>
      </div>
    </section>
  );
};

export default Banner;
