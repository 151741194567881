import React from "react";
import ReactPlayer from "react-player";
import News from "./Noticias";

const About = () => {
  return (
    <>
      <News limit={3} />
      <section className="feature-area section-gap-top section-gap-bottom">
        <div className="container">
          <div className="align-items-center">
            <div className="video">
              <ReactPlayer
                playing={true}
                loop={true}
                volume="0"
                mute={true}
                width="100%"
                height="630px"
                url="https://www.youtube.com/watch?v=_yKZqAo_-Sk&feature=youtu.be"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
