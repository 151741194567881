import React from "react";
import { Link } from "react-router-dom";
import InfoLocal from "./menu/InfoLocal";
import InfoNacional from "./menu/InfoNacional";

const Menu = () => {
  return (
    <>
      <li>
        <Link to={"/"}>Início</Link>
      </li>

      <li>
        <a>Informação Local</a>
        <InfoLocal />
      </li>
      <li>
        <a>Informação Nacional</a>
        <InfoNacional />
      </li>
      <li>
        <Link to={"/noticias"}>Notícias</Link>
      </li>
    </>
  );
};

export default Menu;
