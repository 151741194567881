import React from "react";
import ReactPlayer from "react-player";
import VideoSrc from "../video/Background-35208.mp4";

const Video = () => {
  return (
    <ReactPlayer
      className="video-container video-container-overlay"
      playing={true}
      loop={true}
      volume={0}
      mute={true}
      width="100%"
      height="auto"
      url={VideoSrc}
    />
  );
};

export default Video;
