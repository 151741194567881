import React, { useEffect, useState } from "react";
import Menu from "./Menu.js";

const Header = () => {
  const [fixed, setFixed] = useState(false);
  const [mobile, setMenu] = useState(false);

  function toggle() {
    setMenu(mobile === true ? false : true);
  }

  const handleScroll = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    setFixed(top === 0 ? false : true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header id="header">
      <div className="container main-menu">
        <div className="row align-items-center justify-content-between d-flex">
          <div id="logo">
            SNS 24: <a href="tel:808242424">808 24 24 24</a> |  <a href="mailto:atendimento@sns24.gov.pt">
              atendimento@sns24.gov.pt
            </a>
          </div>
          <nav id="nav-menu-container">
            <ul className="nav-menu">
              <Menu />
            </ul>
          </nav>

          <button type="button" onClick={toggle} id="mobile-nav-toggle">
            <i className="fa fa-bars"></i>
          </button>
        </div>
      </div>

      <nav id="mobile-nav" className={mobile ? "mobile-nav-active" : ""}>
        <ul>
          <Menu />
        </ul>
      </nav>
    </header>
  );
};

export default Header;
