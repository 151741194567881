import React from "react";
import sns24 from "../img/sns-24-2@3x.png";
import InfoLocal from "./menu/InfoLocal";
import InfoNacional from "./menu/InfoNacional";

const Footer = () => {
  return (
    <footer className="footer-area section-gap">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 single-footer-widget">
            <h4>Informação Local</h4>
            <InfoLocal/>
          </div>
          <div className="col-lg-4 col-md-6 single-footer-widget">
            <h4>Informação Nacional</h4>
           <InfoNacional/>
          </div>
          <div className="col-lg-4 col-md-6 single-footer-widget">
            <div className="button-sns">
              <div className="sns-number">
                <a href="tel:+351808242424">808 24 24 24</a>
              </div>
              <div className="sns-email">
                <a href="mailto:atendimento@sns24.gov.pt">
                  atendimento@sns24.gov.pt
                </a>
              </div>
            </div>

            <div className="buttons gray flex-row d-flex">
              <img src={sns24} className="img-fluid" alt="sns24" />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 single-footer-widget">
            Desenvolvimento <a target="_blank" rel="noopener noreferrer" href="http://digitalmente.net">Digitalmente</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
